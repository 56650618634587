import {Component, OnInit} from '@angular/core';
import {RoutePathEnum} from '../RoutePathEnum';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {


  RoutePathEnum = RoutePathEnum;

  constructor() {
  }

  ngOnInit(): void {

  //  ($('#myModal') as any).modal('show');
  }
  closeModal(){
    ($('#myModal') as any).modal('hide')
  }

}
